<template>
    <div v-if="columnData.score" class="icon-speed" :class="'icon-speed--' + columnData.score">IRL Score:
        {{ columnData.score }}
    </div>
</template>

<script>
export default {
    props: {
        rowId: Number,
        columnData: Object,
    }
}
</script>

<style scoped>

</style>
